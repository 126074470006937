/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import { getUser } from "../utils/auth"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            link
            name
          }
        }
      }
    }
  `)

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        menuLinks={data.site.siteMetadata.menuLinks}
      />
      <div style={{ backgroundColor: "white" }}>
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `3rem 1.0875rem 1.45rem`,
          }}
        >
          <main>{children}</main>
        </div>
      </div>
      <footer>
        <div
          style={{
            textAlign: "center",
            padding: `2.5rem 0 1rem`,
          }}
        >
          <h6>© {new Date().getFullYear()} BlueAntWorks</h6>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
