import jwt from "jwt-decode"

const BASE_URL = `https://unryqf2pia.execute-api.us-west-1.amazonaws.com/prod`

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("blueantworks-user")
    ? JSON.parse(window.localStorage.getItem("blueantworks-user"))
    : null

const setUser = user =>
  window.localStorage.setItem("blueantworks-user", JSON.stringify(user))

export async function login({ email, password }) {
  const url = `${BASE_URL}/login`

  try {
    const result = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    })

    const { token: tokenString } = await result.json()

    const { user } = jwt(tokenString)

    user.token = tokenString

    setUser(user)

    return user
  } catch (e) {
    console.log("Failed to log in.", e)

    return null
  }
}

export const isLoggedIn = () => {
  return !!getUser()
}

export const logout = () => {
  setUser(null)
}

// API

export async function getUsers() {
  const user = getUser()

  if (!user) return null

  const url = `${BASE_URL}/users`

  try {
    const result = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })

    const { users } = await result.json()

    return users
  } catch (e) {
    return null
  }
}

export async function createUser({ name, email, password }) {
  const user = getUser()

  if (!user) return null

  const url = `${BASE_URL}/users`

  try {
    const result = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${user.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, email, password }),
    })

    return await result.json()
  } catch (e) {
    return null
  }
}

export async function deleteUser({ id }) {
  const user = getUser()

  if (!user) return null

  const url = `${BASE_URL}/users/${id}`

  try {
    const result = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })

    return await result.json()
  } catch (e) {
    return null
  }
}
