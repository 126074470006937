import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { getUser, logout } from "../utils/auth"

export default function Header({ siteTitle, menuLinks }) {
  const user = getUser()

  const isAdmin = user && user.scopes.includes("admin")

  return (
    <header
      style={{
        background: `darkblue`,
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `6rem 1.0875rem 0`,
        }}
      >
        <h1 style={{ margin: `0 0 20px 0`, fontSize: "2.75rem" }}>
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            {siteTitle}
          </Link>
        </h1>
        <div>
          <ul style={{ display: "flex", flex: 1, margin: 0, flexWrap: "wrap" }}>
            {menuLinks.map(link => (
              <li key={link.name} style={styles.navigationListItem}>
                <h6>
                  <Link
                    to={link.link}
                    style={styles.navigationLink}
                    activeStyle={styles.navigationLinkActive}
                  >
                    {link.name.toUpperCase()}
                  </Link>
                </h6>
              </li>
            ))}
            <li style={{ flex: 1, listStyleType: `none` }} />
            <li key={"admin"} style={styles.navigationListItem}>
              <h6>
                {isAdmin && (
                  <Link
                    to={"/admin"}
                    style={styles.navigationLink}
                    activeStyle={styles.navigationLinkActive}
                  >
                    ADMIN
                  </Link>
                )}
              </h6>
            </li>
            <li key={"login"} style={styles.navigationListItem}>
              <h6>
                {user ? (
                  <a href="/" style={styles.navigationLink} onClick={logout}>
                    LOGOUT ({user.email})
                  </a>
                ) : (
                  <Link
                    to={"/login"}
                    style={styles.navigationLink}
                    activeStyle={styles.navigationLinkActive}
                  >
                    LOGIN
                  </Link>
                )}
              </h6>
            </li>
          </ul>
        </div>
        {/* <div>
          <ul
            style={{
              display: "flex",
              flex: 1,
              margin: 0,
              backgroundColor: "blue",
            }}
          >
            <li key={"admin"} style={styles.navigationListItem}>
              <h6>
                {isAdmin && (
                  <Link
                    to={"/admin"}
                    style={styles.navigationLink}
                    activeStyle={styles.navigationLinkActive}
                  >
                    ADMIN
                  </Link>
                )}
              </h6>
            </li>
          </ul>
        </div> */}
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const styles = {
  navigationListItem: {
    listStyleType: `none`,
    margin: 0,
  },
  navigationLink: {
    display: "inline-block",
    color: `white`,
    textDecoration: `none`,
    padding: `0.7rem`,
    borderBottomWidth: "2px",
    borderBottomStyle: "solid",
    borderBottomColor: "transparent",
    margin: `0 20px 0 0`,
    fontWeight: "500",
  },
  navigationLinkActive: {
    borderBottomColor: "white",
    fontWeight: "bold",
  },
}
